.liteToggleWrapper .gecko-ui-field-type-toggle label:first-child {
	display: none;
}

.liteToggleWrapper .form-group {
	margin-bottom: 0;
}

.limitCardLabel {
	font-size: 11px;
}

.limitCardValue {
	font-size: 30px;
}


.heroSection .accountName {
	font-size: 50px;
	font-weight: 100;
}

.heroSection .accountPackage span {
	font-size: 10px;
	border-radius: 4px;
	padding: 5px;
	text-transform: uppercase;
}

.heroSection .currencySymbol {
	font-weight: bold;
}


.feature-toggle-description {
	margin-left: 10px;
}