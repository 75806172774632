.changelogMeta {
	color: rgba(0,0,0,0.5);
	font-size: 12px;
}

.releaseType {
	background-color: #524078;
	border-radius: 4px;
	color: rgba(255,255,255,1);
	padding: 3px;
}


@media(min-width: 768px) {
	.metaCol {
		max-width: calc(100%/3);
	}
}