.mdc-drawer__header {
	text-align:center;
	padding: 16px 0;
}

.mdc-drawer__header img 
{
	width:40%;
}

.mdc-list {
	color: #314851;
	font-family: "Muli", sans-serif;
	font-size: 15px;
}

.mdc-list a.mdc-list-item {
	border-left: 4px solid transparent;
	font-weight: 500;
}

.mdc-list a.mdc-list-item:hover {
	border-color: #314851;
	color: #314851;
	text-decoration: none;
}

.mdc-list .mdc-list-item--activated {
	border-color: #314851 !important;
	color: #314851 !important;
	text-decoration: none !important;
	background-color: #F9FAFC !important;
}

.mdc-list .mdc-list-item--activated::before,
.mdc-list .mdc-list-item--activated::after {
	background: none !important;
    opacity: 1 !important;
}