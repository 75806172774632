@import url(https://fonts.geckoform.com/muli.css);

.mdc-drawer__header {
	background-color: #F1F3F5;
}


/* TEMP */
.gecko-ui-card
{
	border:none !important;
	border-radius:0 !important;
	margin-bottom: 20px !important;
}

/* .gecko-ui-card:last-child
{
	margin-bottom:0 !important;
} */

.gecko-ui-card .gecko-ui-card-header,
.gecko-ui-card .gecko-ui-card-footer 
{
	background: none;
}

.gecko-ui-card .gecko-ui-card-header h2
{
	font-size: inherit;
}

.gecko-ui-field-type-search
{
	margin-right:-15px;
}

.btn.gecko-ui-btn 
{
	font-weight: 500;
}

.bold
{
	font-weight:600;
}

body
{
	margin: 0;
    height: 100%;
    width: 100%;
    font-family: "Muli", sans-serif;
    font-weight: 300;
    font-size: 15px; 
    padding:0;
    background: #F1F3F5; 
    color: #314851;
}

button,
input,
optgroup,
select,
textarea 
{
  font-size: 15px;
  font-family: "Muli", sans-serif; 
}

.center {
	text-align: center;
}

.app-body {
    padding:0;
	background-color: #F1F3F5;
}


.mainContentWrapper {
	max-width: 1500px;
	margin: 0 auto;
}


a.pointer, .pointer {
	cursor: pointer;
	text-decoration: none !important;
	color: inherit;
}

a.pointer:hover {
	color: inherit;
}

td.block {
	padding: 0 !important;
}

td a.block, a.block {
	padding: 10px 15px;
	display: block;
	width: 100%;
	height: 100%;
}

.placeholder {
	font-size: 20px;
	color: rgba(0,0,0,0.4);
	text-align: center;
	width: 100%;
}



/* Shadows */

.z-depth-1 {
	-webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)
}

.z-depth-2 {
    -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
}

.z-depth-3 {
    -webkit-box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3);
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3);
}

.z-depth-4 {
    -webkit-box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.3);
    box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.3);
}

.z-depth-5 {
    -webkit-box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.3);
    box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.3);
}


/* spacers */ 

.padTopSmall {
	padding-bottom: 10px;
}

.padBottomSmall {
	padding-bottom: 10px;
}


/* colors */

.swatch-darken-1{
	color: rgba(0,0,0,0.10);
}
.swatch-darken-2{
	color: rgba(0,0,0,0.20);
}
.swatch-darken-3{
	color: rgba(0,0,0,0.30);
}
.swatch-darken-4{
	color: rgba(0,0,0,0.40);
}
.swatch-darken-5{
	color: rgba(0,0,0,0.50);
}
.swatch-darken-6{
	color: rgba(0,0,0,0.60);
}
.swatch-darken-7{
	color: rgba(0,0,0,0.70);
}
.swatch-darken-8{
	color: rgba(0,0,0,0.80);
}
.swatch-darken-9{
	color: rgba(0,0,0,0.90);
}
.swatch-darken-100{
	color: rgba(0,0,0,1);
}


/* Custom */

.smallHeader {
	font-size: 14px;
	color: rgba(0,0,0,0.4);
	font-weight: bold;
}



/* Module Search */

.moduleSearch {
	border: none;
	font-size: 18px;
	font-weight: 300;
	padding: 10px;
	width: 100%;
	border-radius: 4px;
    overflow: hidden;
    outline: none;
    opacity: 0.6;
    transition: opacity 200ms;

}

.moduleSearch:hover{
	opacity: 1;
}
.moduleSearch:focus{
	opacity: 1;
}

