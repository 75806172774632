.four-o-four-wrapper
{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    width: 100%;
    height: 100%;
    top:0;
    left:0;
}

.four-o-four-content .image 
{
    display: inline;
}

.four-o-four-content img 
{
    width: 128px;
    height: auto;
}