.gecko-ui-field-type-display label
{
	display: none;
}

.gecko-ui-field-type-display p.description
{
	margin-bottom: 0;
}

.create-form-account-alert ul
{
	padding: 0;
	list-style-type:none;
}

.create-form-account-alert strong
{
	font-weight: bold;
}