.app-header
{	
	background:white;
	padding:10px;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.098), 0px 1px 5px 0px rgba(0, 0, 0, 0.0826);
}

.app-header  .justify-content-end .btn
{
	margin-left: 10px;
}

.menu-is-closed [role=navigation] 
{
	width:0;
}