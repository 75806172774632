
.dashboardWrapper {
    height: calc(100vh - 52px);
}




.systemSearch {
	border: none;
	font-size: 18px;
	font-weight: 300;
	padding: 10px;
	width: 100%;
	border-radius: 4px;
    overflow: hidden;
    outline: none;
    opacity: 0.6;
    transition: opacity 200ms;

}


#spotlight_wrapper {
  position: relative;
  margin: 0 auto;
  width: 628px;
  max-width: 100%;
  height: 56px;
  padding-top: 100px;
  padding-bottom: 100px;
}


#spotlight_wrapper .shared {
	display: block;
  	width: 628px;
    max-width: 100%;
  	height: 56px;
  	margin: auto;
  	outline: none;
  	border: 1px solid rgba(0, 0, 0, 0);
    color: #314851;
  	font-size: 1.6rem;
  	font-family: "Muli", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  	padding: 0 52px;
}

#spotlight {
  
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -moz-box-shadow: 0 25px 60px 10px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 25px 60px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 25px 60px 10px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.2);
  
  color: #000;
  backgroundcolor: #f8f5f4;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjIuNyAyMi41IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMi43IDIyLjUiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxjaXJjbGUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2NzY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgY3g9IjguMSIgY3k9IjguNCIgcj0iNi44Ii8+PGxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2NzY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgeDE9IjEzLjIiIHkxPSIxMi45IiB4Mj0iMjEuNSIgeTI9IjIxLjQiLz48L3N2Zz4=);
  background-position: left 17px center;
  background-repeat: no-repeat;
  background-size: 3.5%;
  
}
#spotlight:-moz-placeholder {
  color: #666666;
}
#spotlight::-moz-placeholder {
  color: #666666;
}
#spotlight:-ms-input-placeholder {
  color: #666666;
}
#spotlight::-webkit-input-placeholder {
  color: #666666;
}


.main{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    background: transparent;
}

#spotlight_wrapper .autocomplete{
    position: absolute;    
    top: 0;
    left: 0; 
    /*background: transparent;*/
    z-index: 10;
    opacity: 0.4;
}


label[for=spotlight] {
	color: rgba(0,0,0,0.5);
	position: absolute;
	font-size: 1.6rem;
    font-family: "Muli", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  	top: 8px;
    left: 50px;
}


