.gecko-collapsible-container {
    /*background: #f12e50;*/
    width: 100%;
    min-width: 15em;
    /*transform: translate(-50%, -50%);*/
    /*overflow: hidden;*/
}
.gecko-collapsible-container .title {
    color: #fff;
    background: #c80e3d;
    text-align: center;
    font-weight: 700;
    line-height: 1.2em;
    padding: 1em;
}

.section {
    position: relative;
    width: 100%;
    background-color: rgba(255,255,255,1);
    /*border-bottom: 5px solid #c80e3d;
    border-left: 2px solid #c80e3d;
    border-right: 2px solid #c80e3d;*/

    -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.098), 0px 1px 5px 0px rgba(0, 0, 0, 0.0826);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.098), 0px 1px 5px 0px rgba(0, 0, 0, 0.0826);
    transition: margin .2s ease-in;
    will-change: margin;
}


.section .buttonArray {
    position: absolute;
    right: 3em;
    opacity: 0;
    height: 55px;
}

.section:hover .buttonArray, .section.open .buttonArray {
    will-change: opacity;
    opacity: 1;
}

.section .buttonArray .buttonItem {
    float: right;
    padding: 5px;
    margin-right: 10px;
    font-size: 18px;
    color: rgba(0,0,0,0.6);
}

.section .buttonArray .buttonItem:hover {
    color: rgba(0,0,0,0.9);
}

.section button {
    position: absolute;
    right: 0;
    margin: 0;
    padding: 0;
    height: 3em;
    width: 3em;
    outline: 0;
    border: 0;
    background: none;
    text-indent: -9999%;
    pointer-events: none;
}
.section button:before {
    content: '';
    display: block;
    position: absolute;
    height: 10px;
    width: 3px;
    border-radius: .3em;
    background: #888;
    transform-origin: 50%;
    top: 50%;
    left: 50%;
    transition: all .25s ease-in-out;
    transform: translate(75%, -50%) rotate(45deg);
}
.section button:after {
    content: '';
    display: block;
    position: absolute;
    height: 10px;
    width: 3px;
    border-radius: .3em;
    background: #888;
    transform-origin: 50%;
    top: 50%;
    left: 50%;
    transition: all .25s ease-in-out;
    transform: translate(-75%, -50%) rotate(-45deg);
}

.section.open button:before, .section.open button:after {
    height: 14px;
    border-radius: none;
}
.section.open button:before {
    transform: translate(0%, -50%) rotate(-45deg);
}
.section.open button:after {
    transform: translate(0%, -50%) rotate(45deg);
}

.section.open {
    
    margin-top: 20px;
    margin-bottom: 20px;
}


.articlewrap {
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease-in-out;
    will-change: max-height;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.section.open .articlewrap {
    max-height: 500px;

}

.sectionhead {
    width: 100%;
    overflow: hidden;
    background: white;
    cursor: pointer;
    /*font-weight: 700;*/
    color: #888;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 1em;
    padding-right: 2.1em;
    /*border-bottom: 1px solid rgba(0,0,0,0.1);*/
}

.section.open .sectionhead {
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.article {
    padding: 1em;
    color: #333;
    line-height: 1.3;
}

